<script>
export default {
    props: {
        //
    },

    data() {
        return {

        };
    },

    created() {

    },

    methods: {

    }
};
</script>

<template>
    <a class="previous__btn btn mb-2" @click="$emit('click')">
        <i class="mdi mdi-arrow-left mr-2"></i>Previous
    </a>
</template>