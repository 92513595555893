<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import MessageModal from "@/components/modals/message-modal";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";
import SaveButton from "@/components/buttons/save-button";
import CancelButton from "@/components/buttons/cancel-button";

export default {
	components: {
		MessageModal,
		ConfirmationModal,
		InputGroup,
		SelectGroup,
		SaveButton,
		CancelButton
	},
	props: {
		parentInfo: Object,
		gradeOptions: Array,
	},
	data() {
		const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

		return {
			id: id,
			modeName,
			data: {
				requestor_name: '',
				phone: '',
				email: '',
				requestor_type: '',
				postal: '',
				type_of_lesson: '',
				student_name: '',
				student_level: '',
				other_student_level: '',
				application_status: '',
				address: '',
				unit: '',
			},
			applicationStatusOptions: [
                { name: 'Approved', value: 'approved' },
                { name: 'Rejected', value: 'rejected' },
				{ name: 'Pending', value: 'pending' },
            ],
			errors: {},
			saveBtnDisabled: false,
		};
	},
	mounted() {
console.log('PI', this.parentInfo)
console.log('SL', this.gradeOptions)
	},
	computed: {
		nameInput() {
			return this.modeName === 'Add' ? this.data.requestor_name : this.parentInfo.requestor_name;
		},
		phoneInput() {
			return this.modeName === 'Add' ? this.data.phone : this.parentInfo.phone;
		},
		emailInput() {
			return this.modeName === 'Add' ? this.data.email : this.parentInfo.email;
		},
		requestorTypeInput() {
			return this.modeName === 'Add' ? this.data.requestor_type : this.parentInfo.requestor_type;
		},
		postalInput() {
			return this.modeName === 'Add' ? this.data.postal : this.parentInfo.postal;
		},
		typeOfLessonInput() {
			return this.modeName === 'Add' ? this.data.type_of_lesson : this.parentInfo.type_of_lesson;
		},
		studentNameInput() {
			return this.modeName === 'Add' ? this.data.student_name : this.parentInfo.student_name;
		},
		studentLevelInput() {
			return this.modeName === 'Add' ? this.data.student_level : this.parentInfo.student_level;
		},
		otherStudentLevelInput() {
			return this.modeName === 'Add' ? this.data.other_student_level : this.parentInfo.other_student_level;
		},
		applicationStatusInput() {
			return this.modeName === 'Add' ? this.data.application_status : this.parentInfo.application_status;
		},
		addressInput() {
			return this.modeName === 'Add' ? this.data.address : this.parentInfo.address;
		},
		unitInput() {
			return this.modeName === 'Add' ? this.data.unit : this.parentInfo.unit;
		}
	},
	methods: {
		updateName(value) {
			if (this.modeName === 'Add') {
				this.data.requestor_name = value;
			} else {
				this.parentInfo.requestor_name = value;
			}
		},
		updatePhone(value) {
			if (this.modeName === 'Add') {
				this.data.phone = value;
			} else {
				this.parentInfo.phone = value;
			}
		},
		updateEmail(value) {
			if (this.modeName === 'Add') {
				this.data.email = value;
			} else {
				this.parentInfo.email = value;
			}
		},
		updateRequestorType(value) {
			if (this.modeName === 'Add') {
				this.data.requestor_type = value;
			} else {
				this.parentInfo.requestor_type = value;
			}
		},
		updatePostal(value) {
			if (this.modeName === 'Add') {
				this.data.postal = value;
			} else {
				this.parentInfo.postal = value;
			}
		},
		updateTypeOfLesson(value) {
			if (this.modeName === 'Add') {
				this.data.type_of_lesson = value;
			} else {
				this.parentInfo.type_of_lesson = value;
			}
		},
		updateStudentName(value) {
			if (this.modeName === 'Add') {
				this.data.student_name = value;
			} else {
				this.parentInfo.student_name = value;
			}
		},
		updateStudentLevel(value) {
			if (this.modeName === 'Add') {
				this.data.student_level = value;
			} else {
				this.parentInfo.student_level = value;
			}

			// if (value !== 'others') {
			// 	if (this.modeName === 'Add') {
			// 		this.data.other_student_level = '';
			// 	} else {
			// 		this.parentInfo.other_student_level = '';
			// 	}
			// }
		},
		updateOtherStudentLevel(value) {
			if (this.modeName === 'Add') {
				this.data.other_student_level = value;
			} else {
				this.parentInfo.other_student_level = value;
			}
		},
		updateApplicationStatus(value) {
			if (this.modeName === 'Add') {
				console.log('added')
				this.data.application_status = value;
			} else {
				this.parentInfo.application_status = value;
			}
		},
		updateAddress(value) {
			if (this.modeName === 'Add') {
				this.data.address = value;
			} else {
				this.parentInfo.address = value;
			}
		},
		updateUnit(value) {
			if (this.modeName === 'Add') {
				this.data.unit = value;
			} else {
				this.parentInfo.unit = value;
			}
		},
		async save() {
			this.errors = {};
			let result;
			
			if (this.modeName === 'Add') {
				this.saveBtnDisabled = true;
				let requestData = this.data;

				if (this.data.student_level === 'others' && this.data.other_student_level === '') {
					this.saveBtnDisabled = false;
					this.errors.other_student_level = ['Please specify'];
					return;
				}

				if (this.data.application_status === '' || this.data.application_status === null || this.data.application_status === undefined) {
					this.saveBtnDisabled = false;
					this.errors.application_status = ['Status is required'];
					return;
				}
				
				result = await axios.post(`${process.env.VUE_APP_APIURL}/assignment`, requestData, {
					headers: authHeader(),
				});
			} else {
				this.saveBtnDisabled = true;
				console.log('PARENTINFO', this.parentInfo)
				if (this.parentInfo.requestor_name.length === 0) {
					this.saveBtnDisabled = false;
					this.errors.requestor_name = ['Requestor name is required'];
					return this.errors;
				} else if (this.parentInfo.phone.length === 0) {
					this.saveBtnDisabled = false;
					this.errors.phone = ['Phone is required'];
					return this.errors;
				} else if (!/^\d{8}$/.test(this.parentInfo.phone)) {
					this.saveBtnDisabled = false;
					this.errors.phone = ['Phone should be numeric and in length of 8'];
					return this.errors;
				} else if (this.parentInfo.email.length === 0) {
					this.saveBtnDisabled = false;
					this.errors.email = ['Email is required'];
					return this.errors;
				} else if (!/\S+@\S+\.\S+/.test(this.parentInfo.email)) {
					this.saveBtnDisabled = false;
					this.errors.email = ['Email must be a valid email address'];
					return this.errors;
				} else if (this.parentInfo.postal.length === 0) {
					this.saveBtnDisabled = false;
					this.errors.postal = ['Postal is required'];
					return this.errors;
				} else if (this.parentInfo.student_name.length === 0) {
					this.saveBtnDisabled = false;
					this.errors.student_name = ['Student name is required'];
					return this.errors;
				} else if (this.parentInfo.student_level.length === 0) {
					this.saveBtnDisabled = false;
					this.errors.student_level = ['Student level is required'];
					return this.errors;
				} else if (this.parentInfo.address.length === 0) {
					this.saveBtnDisabled = false;
					this.errors.address = ['Address is required'];
					return this.errors;
				} else if (this.parentInfo.application_status === null || this.parentInfo.application_status === undefined) {
					this.saveBtnDisabled = false;
					this.errors.application_status = ['Status is required'];
					return this.errors;
				}

				this.parentInfo.status = 1;

				const filteredPairs = Object.entries(this.parentInfo).filter(([key]) => [
					'requestor_name', 
					'phone',
					'email',
					'requestor_type',
					'postal',
					'type_of_lesson',
					'student_name',
					'student_level',
					'other_student_level',
					'application_status',
					'address',
					'unit',
					'status'
				].includes(key));

                const requestData = Object.fromEntries(filteredPairs);
				result = await axios.put(`${process.env.VUE_APP_APIURL}/assignment/${this.id}`, requestData, {
					headers: authHeader(),
				});
			}

			if (result) {
				if (result.data['code'] === 'success') {
					if (this.modeName === 'Add') {
						window.location.href = `${window.location.origin}/parent_request/form/${result.data.data.assignment.assignment_id}`
						this.saveBtnDisabled = false;
					} else {
						this.saveBtnDisabled = false;
						this.$refs.messageModal.showModal('Your record has been updated successfully');
					}
				} else if (result.data['code'] === 'invalid_field') {
					this.saveBtnDisabled = false;
					this.errors = result.data['errors'];
				}
			}
		},
		async cancel() {
			this.$router.push({ name: "Parent Request" });
		}
	},
};
</script>

<template>
	<div>
		<MessageModal ref="messageModal" />
		<ConfirmationModal ref="confirmationModal" />		

		<div class="card" style="border-radius: 0;">
			<div class="card-body p-4" stlye="box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);">
				<div class="row">
					<div class="col-xs-12 col-md-4">
						<InputGroup type="text" id="requestor_name" displayName="Name" :value="nameInput" @input="updateName" :error="errors.requestor_name" />
					</div>
					<div class="col-xs-12 col-md-4">
						<InputGroup type="text" id="phone" displayName="Contact number" :value="phoneInput" @input="updatePhone" :error="errors.phone" />
					</div>
					<div class="col-xs-12 col-md-4">
						<InputGroup type="email" id="email" displayName="Email address" :value="emailInput" @input="updateEmail" :error="errors.email" />
					</div>

					<div class="col-xs-12 col-md-4">
						<div class="form-group">
							<div class="d-flex align-items-center flex-wrap">
								<label class="mb-0 mr-3 d-block w-100">Requestor is a parent or student?</label>
								<div class="d-flex mt-3 radio">
									<input type="radio" id="parent" value="parent" name="requestor_type" role="button" :checked="modeName === 'Add' ? data.requestor_type === 'parent' : parentInfo.requestor_type === 'parent'" @change="updateRequestorType($event.target.checked ? 'parent' : '')">
									<label class="mb-0 ml-2 mr-3" for="parent" role="button">Parent</label>
									<input type="radio" class="ml-5" id="student" value="student" name="requestor_type" role="button" :checked="modeName === 'Add' ? data.requestor_type === 'student' : parentInfo.requestor_type === 'student'" @change="updateRequestorType($event.target.checked ? 'student' : '')"> 
									<label class="mb-0 ml-2 mr-3" for="student" role="button">Student</label>
								</div>
							</div>
						</div>
						<div v-if="errors.requestor_type" role="alert" class="alert alert-danger mt-4">
							Please select requestor type
						</div>
					</div>					
					<div class="col-xs-12 col-md-4">
						<div class="form-group">
							<div class="d-flex align-items-center flex-wrap">
								<label class="mb-0 mr-3 d-block w-100">Type of lesson</label>
								<div class="d-flex mt-3 radio">
									<input type="radio" id="physical" value="physical" name="type_of_lesson" role="button" :checked="modeName === 'Add' ? data.type_of_lesson === 'physical' : parentInfo.type_of_lesson === 'physical'" @change="updateTypeOfLesson($event.target.checked ? 'physical' : '')">
									<label class="mb-0 ml-2 mr-3" for="physical" role="button">Physical</label>
									<input type="radio" class="ml-5" id="online" value="online" name="type_of_lesson" role="button" :checked="modeName === 'Add' ? data.type_of_lesson === 'online' : parentInfo.type_of_lesson === 'online'" @change="updateTypeOfLesson($event.target.checked ? 'online' : '')">
									<label class="mb-0 ml-2 mr-3" for="online" role="button">Online</label>
								</div>
							</div>
						</div>
						<div v-if="errors.type_of_lesson" role="alert" class="alert alert-danger mt-4">
							Please select type of lesson
						</div>
					</div>

					<div class="col-xs-12 col-md-4">
						<InputGroup type="text" id="student_name" displayName="Student name" :value="studentNameInput" @input="updateStudentName" :error="errors.student_name" />
					</div>
					<div class="col-xs-12 col-md-4">
						<SelectGroup id="student_level" displayName="Student grade / level" :value="studentLevelInput" @input="updateStudentLevel" :error="errors.student_level" :options="gradeOptions" />
					</div>
					<div v-if="studentLevelInput === 'others'" class="col-xs-12 col-md-4">
						<InputGroup type="text" id="other_student_level" displayName="Other student grade / level" :value="otherStudentLevelInput" @input="updateOtherStudentLevel" :error="errors.other_student_level" />						
					</div>

					<div class="col-xs-12 col-md-4">
						<InputGroup type="text" id="address" displayName="Address" :value="addressInput" @input="updateAddress" :error="errors.address" />						
					</div>
					<div class="col-xs-12 col-md-4">
						<InputGroup type="text" id="unit_no" displayName="Unit (optional)" :value="unitInput" @input="updateUnit" :error="errors.unit" />						
					</div>
					<div class="col-xs-12 col-md-4">
						<InputGroup type="text" id="postal_code" displayName="Postal code" :value="postalInput" @input="updatePostal" :error="errors.postal" />
					</div>
				</div>

				<div class="row">
					<div class="col-12 col-md-4">
						<SelectGroup id="status" displayName="Status" :value="applicationStatusInput" @input="updateApplicationStatus" :error="errors.application_status" :options="applicationStatusOptions" />
					</div>
				</div>

				<div class="divider"></div>

				<div class="row mt-2">
					<div class="col-12" style="text-align: right;">
						<CancelButton @click="cancel"></CancelButton>
						<SaveButton @click="save" :text="'Save and continue'" :disabled="saveBtnDisabled" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>