<script>
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    props: {
        index: {
            default: -1,
        },
        id: {
            required: true,
            type: String,
        },
        displayName: {
            required: true,
            type: String,
        },
        value: {
            required: true,
            type: [Array, String],
            default() {
                return [];
            },
        },
        options: {
            required: true,
            default() {
                return [];
            }
        },
        error: [],

        hideLabel: {
            type: Boolean,
        },
        onInitial: {

        },
    },

    data() {
        return {
            oldSelected: null,
            selected: [],
        };
    },

    mounted() {
        this.select(this.value);
        if (this.onInitial) {
            this.onInitial(this.index);
        }
    },

    watch: {
        value(newValue) {
            this.select(newValue);
        },

        options(newValue) {
            if (newValue && newValue.length) {
                this.select(this.value);
            }
        },

        selected(newValue) {
            if (newValue === null) {
                if (this.value !== null) {
                    this.handleInput(null);
                    return;
                }
            }

            if (this.value === null) {
                this.handleInput(null);
                return;
            }

            if (!this.allMatched(newValue, this.value)) {
                this.handleInput(newValue);
            }
        },
    },

    methods: {
        select(newValue) {
            if (typeof newValue === 'string') {
                try {
                    newValue = JSON.parse(newValue);
                } catch (e) {
                    newValue = [];
                }
            }

            if (newValue === null) {
                this.selected = null;
                return;
            }

            if (this.selected === null || !this.allMatched(this.selected, newValue)) {
                this.selectItem(newValue);
            }
        },

        selectItem(newValue) {
            if (newValue === null) {
                this.selected = null;
                return;
            }

            const selections = [];
            for (const value of newValue) {
                const selectionItem = this.getSelectionItem(this.options, value);
                if (selectionItem) {
                    selections.push(selectionItem);
                }
            }
            
            this.selected = selections;
        },

        allMatched(selected, values) {
            if (selected === null || values === null) {
                return selected === null && values === null;
            }

            let matched = true;
            const selectedValues = selected.map(item => item.value);

            for (const value of values) {
                if (!selectedValues.includes(value)) {
                    matched = false;
                    break;
                }
            }

            return matched;
        },

        getSelectionItem(options, value) {
            return options.find(option => option.value === value) || null;
        },

        handleInput(values) {
            if (values === null) {
                this.$emit('input', []);
            } else {
                const selections = values.map(value => value.value);
                this.$emit('input', selections);
            }
        },
    }
};
</script>

<template>
    <div class="form-group">
        <div>
            <label :for="id" v-if="hideLabel === false">{{ displayName }}</label>
            <multiselect class="pl-0 pr-0" v-model="selected" :options="options" :multiple="true" :close-on-select="false"
                :placeholder="`Select ${displayName}`" :searchable="true" label="name" track-by="value">
            </multiselect>
        </div>
        <div class="alert alert-danger mt-3" role="alert" v-if="error && error.length > 0">
            {{ error[0] }}
        </div>
    </div>
</template>