<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import MessageModal from "@/components/modals/message-modal";
import PreviousButton from "@/components/buttons/previous-button";
import SaveButton from "@/components/buttons/save-button";

export default {
	components: {
		MessageModal,
		PreviousButton,
		SaveButton,
	},
	props: {
		parentInfo: Object,
		subjectDurationOptions: Array,
		subjectFrequencyOptions: Array,
		tutorTypeOptions: Array,
	},
	data() {
		const id = this.$route.params.id;
		const modeName = id === 'add' ? 'Add' : 'Edit';
		
		return {
			id: id,
			modeName,
			formData: {
				remarks: ''
			},
			saveBtnDisabled: false,
		}
	},
	computed: {		
		durationText() {
			const option = this.subjectDurationOptions.find(item => item.value == this.parentInfo.subject_duration_name);
			return option ? option.name : '';
		},
		frequencyText() {
			const option = this.subjectFrequencyOptions.find(item => item.value == this.parentInfo.subject_frequency_name);
			return option ? option.name : '';
		},
		tutorText() {
			const option = this.tutorTypeOptions.find(item => item.value == this.parentInfo.tutor_type);
			return option ? option.name + ' ($' + option.rate + '/hr)' : '';
		},
		remarksInput() {
            return this.formData.remarks || this.parentInfo.remarks;
        },
		formattedTutorInfo() {
			let tutorTypes = [];
			let tutorRates = [];

			if (typeof this.parentInfo.tutor_type === 'string' && this.parentInfo.tutor_type.trim() !== '') {
				try {
					tutorTypes = JSON.parse(this.parentInfo.tutor_type);
				} catch (error) {
					console.error("Failed to parse tutor_type:", error);
					return '-';
				}
			} else if (Array.isArray(this.parentInfo.tutor_type)) {
				tutorTypes = this.parentInfo.tutor_type;
			} else {
				return '-';
			}

			if (typeof this.parentInfo.tutor_rate === 'number') {
				tutorRates = this.parentInfo.tutor_rate;
			} else if (typeof this.parentInfo.tutor_rate === 'string' && this.parentInfo.tutor_rate.trim() !== '') {
				try {
					// const cleanedTutorRate = this.parentInfo.tutor_rate.replace(/[$]/g, '');
					tutorRates = JSON.parse(this.parentInfo.tutor_rate);
					// tutorRates = JSON.parse(cleanedTutorRate);
				} catch (error) {
					console.error("Failed to parse tutor_rate:", error);
					return '-';
				}
			} else {
				return '-';
			}

			if (!Array.isArray(tutorTypes)) {
				return '-';
			}

			if (typeof tutorRates === 'number') {
				let rateDisplay = this.parentInfo.service_id == 5 ? `$${tutorRates}/session` : `$${tutorRates}/hr`;
				
				const formattedInfo = tutorTypes.map((type) => {
					return `${type.name} (${rateDisplay})`;
				});
				return formattedInfo.join(', ');
			} else {
				console.log('new', tutorRates)
				const tutorRatesMap = tutorRates.reduce((acc, rate) => {
					acc[rate.id] = rate.rate;
					return acc;
				}, {});

				const formattedInfo = tutorTypes.map((type) => {
					console.log('typetype', tutorRatesMap)
					const rate = tutorRatesMap[type.id];

					let rateDisplay = rate;
					console.log('this.parentInfo', this.parentInfo)
					if (this.parentInfo.preferred_budget == 1) {
						rateDisplay = this.parentInfo.service_id == 5 
							? `$${rate}/session`
							: `$${rate}/hr`;
					}

					return `${type.name} (${rateDisplay})`;
				});
				return formattedInfo.join(', ');
			}
		},
	},
	watch: {
		remarksInput(value) {
            if (this.parentInfo.remarks && value !== this.parentInfo.remarks) {
                this.formData.remarks = value;
            }
        },
	},
	mounted() {
		//
	},
	methods: {
		sanitizeTitle(text) {
			let formattedText = '';
			if (text) {
				formattedText = text
				.split("_")
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join(" ");
			}
            
			return formattedText;
        },
		formatGenderPrefernce(gender) {
			if (!gender) return "-";

			const formattedText = gender
				.split("_")
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join(" ");

			return formattedText;
		},
		handleRemarksInput(value) {
			this.formData.remarks = value;
        },
		async save() {
			this.saveBtnDisabled = true;
			this.errors = {};
			let result;

			if (this.modeName === 'Add') {
				this.saveBtnDisabled = false;
				window.alert('Please fill personal info first');
				this.goToFirstTab();
			} else {
				if (!this.formData.remarks) {
					this.formData.remarks = this.parentInfo.remarks;
				}
				
				const requestData = this.formData;
				result = await axios.put(`${process.env.VUE_APP_APIURL}/assignment/${this.id}`, requestData, {
					headers: authHeader(),
				});
			}
			
			if (result) {
				if (result.data['code'] === 'success') {
					this.saveBtnDisabled = false;
					this.$refs.messageModal.showModal('Your record has been updated successfully');
				} else if (result.data['code'] === 'invalid_field') {
					this.saveBtnDisabled = false;
					this.errors = result.data['errors'];
				}
			}
		},
		goToFirstTab() {
			this.$emit('go-to-first-tab');
		},
		goToPrevious() {
			this.$emit('go-to-previous');
		}
	}
}
</script>

<template>
	<div>
		<MessageModal ref="messageModal" />
		<div class="card" style="border-radius: 0;">
			<div class="card-body p-4" stlye="box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);">
				<div class="row mb-5">
					<div class="col-12">
						<label class="label">Session details</label>

						<div class="divider mt-0"></div>
					</div>

					<div class="col-12 col-md-3 mb-2">
						<p class="title">Service</p>
						<p class="desc">{{ sanitizeTitle(parentInfo.service_name) || '-' }}</p>
					</div>
					<div class="col-12 col-md-3 mb-2">
						<p class="title" v-if="parentInfo.service_id == 1 || parentInfo.service_id == 2">Subject</p>
						<p class="title" v-else-if="parentInfo.service_id == 3 || parentInfo.service_id == 4">Therapy service</p>
						<p class="title" v-else-if="parentInfo.service_id == 5">Enrichment service</p>
						<p class="title" v-else>Subject</p>
						<p class="desc">{{ parentInfo.subject_name || '-' }}</p>
					</div>
					<div class="col-12 col-md-3 mb-2">
						<p class="title" v-if="parentInfo.service_id == 1 || parentInfo.service_id == 2">Duration per subject</p>
						<p class="title" v-else-if="parentInfo.service_id == 3 || parentInfo.service_id == 4">Duration per type of service</p>
						<p class="title" v-else-if="parentInfo.service_id == 5">Duration per type of enrichment</p>
						<p class="title" v-else>Duration</p>
						<p class="desc">
							{{ parentInfo.subject_duration_name 
								? (parentInfo.subject_duration_name > 1 
									? parentInfo.subject_duration_name + " minutes/lesson" 
									: parentInfo.subject_duration_name + " minute/lesson") 
								: '-' 
							}}
						</p>
					</div>
					<div class="col-12 col-md-3 mb-2">
						<p class="title" v-if="parentInfo.service_id == 1 || parentInfo.service_id == 2">Frequency per subject</p>
						<p class="title" v-else-if="parentInfo.service_id == 3 || parentInfo.service_id == 4">Frequency per type of service</p>
						<p class="title" v-else-if="parentInfo.service_id == 5">Frequency per type of enrichment</p>
						<p class="title" v-else>Frequency</p>
						<p class="desc">
							{{ parentInfo.subject_frequency_name 
								? (parentInfo.subject_frequency_name > 1 
									? parentInfo.subject_frequency_name + " lessons/week" 
									: parentInfo.subject_frequency_name + " lesson/week") 
								: '-' 
							}}
						</p>
					</div>
					<div class="col-12 col-md-3 mb-2">
						<p class="title" v-if="parentInfo.service_id == 1 || parentInfo.service_id == 2">Tutor types</p>
						<p class="title" v-else-if="parentInfo.service_id == 3 || parentInfo.service_id == 4">Service professionals</p>
						<p class="title" v-else-if="parentInfo.service_id == 5">Enrichment specialist</p>
						<p class="title" v-else>Service Provider Type</p>
						<p v-if="formattedTutorInfo && parentInfo.other_tutor_type" class="desc">
							{{ formattedTutorInfo + ', ' + parentInfo.other_tutor_type + (parentInfo.preferred_budget == 1 ? ' $(' + parentInfo.tutor_rate + '/hr)' : '') }}
						</p>
						<p v-else-if="formattedTutorInfo" class="desc">
							{{ formattedTutorInfo }}
						</p>
						<p v-else-if="parentInfo.other_tutor_type" class="desc">
							{{ parentInfo.other_tutor_type + (parentInfo.preferred_budget == 1 ? ' $(' + parentInfo.tutor_rate + '/hr)' : '') }}
						</p>
					</div>
					<div class="col-12 col-md-3 mb-2">
						<p class="title">Agreeable to the market rates?</p>
						<p v-if="parentInfo.preferred_budget == 1" class="desc">No</p>
						<p v-else-if="parentInfo.preferred_budget == 0" class="desc">Yes</p>
						<p v-else-if="parentInfo.preferred_budget == 2" class="desc">To be quoted by Service Providers</p>
					</div>
					<div class="col-12 col-md-3 mb-2">
						<p class="title">Gender preference</p>
						<p class="desc">{{ formatGenderPrefernce(parentInfo.gender_preference) || '-' }}</p>
					</div>
					<div class="col-12 col-md-3 mb-2">
						<p class="title">All available days and timings</p>
						<p class="desc">{{ parentInfo.preferred_time || '-' }}</p>
					</div>

					<div class="col-12 mt-4">
						<label for="remarks" class="label">Remarks (Optional)</label><br>
						<textarea id="remarks" cols="30" rows="8" placeholder="Add any specific details or requests you have for this service (optional)" :value="remarksInput" @input="handleRemarksInput($event.target.value)">

						</textarea>
					</div>
				</div>
 
				<div class="divider"></div>

				<div class="row mt-2">
					<div class="col-12" style="text-align: right;">
						<PreviousButton @click="goToPrevious" />
						<SaveButton @click="save" :text="'Save'" :disabled="saveBtnDisabled" style="float: right" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
	.title {
		font-family: Nunito;
		font-size: 14px;
		font-weight: 500;
		color: #505D69;
		margin-bottom: 0;
	}

	.desc {
		font-family: Nunito;
		font-size: 16px;
		font-weight: 700;
		color: #505D69;
		margin-bottom: 0;
	}

	textarea {
		width: 100%;
		border: 1px solid #CDCDCD;
		border-radius: 6px;
		padding: 13px 12px;
	}

	textarea:focus {
		outline: 0;
	}
</style>